@import-normalize; /* bring in normalize.css styles */

html, body {
  height: 100%;
  background: radial-gradient(#008531, #031f00) center center no-repeat fixed, #031f00;
  background-size: cover;
}

* {
  font-family: 'Sixtyfour' !important;
  cursor: crosshair !important;
}

.lit-games-banner {
  width: 100%;
  max-width: 300px;
}

* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

button, input {
  user-select: all;
  touch-action: all;
}

body {
  margin: 0;
  padding: 0;
}

h1, p, input, select, button {
  margin: 0;
}

a {
  color:inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

canvas {
  user-select: none;
  touch-action: manipulation;
}
